import http from "../utils/http-common";

class ReservationService {
  getAll() {
    return http.get("/reservation");
  }

  get(id) {
    return http.get(`/reservation/${id}`);
  }

  create(data) {
    return http.post("/reservation", data);
  }

  createDocument(data) {
    return http.post("/reservation/doc", data);
  }

  update(id, data) {
    return http.put(`/reservation/${id}`, data);
  }

  delete(id) {
    return http.delete(`/reservation/${id}`);
  }
}

export default new ReservationService();