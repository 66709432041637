<template>
  <div style="overflow: unset">
    <data-form2
      :key="'pay' + nextKey"
      :data="itemData"
      :formFields="formFields"
      minheight="300px"
      :nooverflow="true"
      @changed="inputChanged"
      :readonly="true"
    />
  </div>
</template>
<script>
import DataForm2 from "../widgets/DataForm2.vue";
import ExchangeRatesDataService from "../../services/exchange-rates.service";

export default {
  name: "normal-form",
  props: {},
  components: {
    DataForm2,
  },
  data() {
    return {
      nextKey: 0,
      paymentKey: 0,
    };
  },
  computed: {
    taxRates() {
      return this.$store.state.app.taxRates;
    },
    formFields() {
      return {
        rows: [
          [
            { price_exc: { label: "Iznos u valuti", type: "number" } },
            {
              osnovica: {
                label: "Osnovica za proviziju",
                type: "number",
              },
            },
            { osnovica_p: { label: "% provizije", type: "number" } },
          ],

          [
            { its_no: { label: "ITS No." } },
            { incoming_invoice: { label: "Broj ulazne fakture" } },
            {},
          ],
          [
            {
              exchange_date: {
                label: "Datum kursa",
                type: "date",
                max: new Date().toISOString().substring(0, 10),
              },
            },
            {
              exchange: {
                label: "Valuta",
                type: "dropdown",
                dropdownHeight: "150px",
                list: [
                  { id: "eur", name: "EUR" },
                  { id: "usd", name: "USD" },
                  { id: "aud", name: "AUD" },
                  { id: "chf", name: "CHF" },
                  { id: "gbp", name: "GBP" },
                  { id: "sek", name: "SEK" },
                  { id: "eur_adiko", name: "EUR Adiko" },
                  { id: "usd_adiko", name: "USD Adiko" },
                  { id: "aud_adiko", name: "AUD Adiko" },
                  { id: "chf_adiko", name: "CHF Adiko" },
                  { id: "gbp_adiko", name: "GBP Adiko" },
                  { id: "sek_adiko", name: "SEK Adiko" },
                ],
              },
            },
            // { val_date: { label: "Datum valute", type: "date" } },
            {
              exchange_value: {
                label: "Kurs",
                type: "number",
                description:
                  "Ukoliko kurs nije definisan za izabrani datum, možete ga preuzeti pomoću forme Kursna lista",
                key: "exc" + this.excKey,
              },
            },
          ],
          [
            {
              price: {
                label: "Iznos",
                type: "hidden",
                disabled: true,
                key: this.paymentKey,
              },
            },
            {},
            {},
            {},
          ],
        ],
      };
    },
    itemData() {
      // console.log({ route: this.$route.params.id, invoice: this.invoice });
      return {
        its_no: this.its_no,
        incoming_invoice: this.incoming_invoice,
        exchange: "eur",
        exchange_value: this.getExchange(new Date()),
        exchange_date: new Date().toISOString().substring(0, 10),
        osnovica: 0.0,
        osnovica_p: 0.0,
      };
    },
    cachedVals() {
      return {
        price: "",
        price_exc: "",
        payment: "",
        payment_exc: "",
      };
    },
  },
  methods: {
    inputChanged(e, name) {
      this.itemData[name] = e;

      if (name.startsWith("price_exc")) {
        this.itemData[name] = Number.parseFloat(e).toFixed(2);
      }

      if (name == "exchange") {
        this.getExchange(this.itemData.exchange_date);
      }

      if (name == "exchange_date") {
        this.getExchange(e);
      }

      this.paymentKey++;

      this.$emit("onChanged", this.itemData);
    },
    getExchange(date) {
      ExchangeRatesDataService.getByDate(date).then((res) => {
        const exc = res.data[this.itemData.exchange];
        this.itemData.exchange_value = exc;
        this.nextKey++;
      });
    },
  },
};
</script>