import http from "../utils/http-common";

class InvoiceDataService {
  getAll() {
    return http.get("/invoice");
  }

  get(id) {
    return http.get(`/invoice/${id}`);
  }

  getNext(type, stationsId) {
    return http.get(`/invoice/nr/${type}/${stationsId}`);
  }

  getFromTo(type, from, to) {
    return http.get(`/invoice/fromto/${type}?from=${from}&to=${to}`);
  }

  getByRef(ref_doc_nr) {
    return http.get(`/invoice/ref/${ref_doc_nr}`);
  }

  exportInvoices(data) {
    return http.post(`/invoice/export`, data);
  }

  getByIds(ids) {
    return http.post(`/invoice/ids`,{ids: ids});
  }

  create(data) {
    return http.post("/invoice", data);
  }

  createDocument(data) {
    return http.post("/invoice/type", data);
  }

  update(id, data) {
    return http.put(`/invoice/${id}`, data);
  }

  delete(id) {
    return http.delete(`/invoice/${id}`);
  }

  toSaop(id, storno = false) {
    if (!storno) {
      return http.post(`/invoice/saop/invoice`, { id: id });
    } else {
      return http.post(`/invoice/saop/invoice`, { id: id, storno: 'Y' });
    }
  }
}

export default new InvoiceDataService();