<template>
  <div class="container">
    <div class="row" v-if="showForm">
      <normal-form @onChanged="inputChanged"></normal-form>
    </div>
    <div class="row" v-if="this.proform['invoice_type'] == 'Proform'">
      <div class="col fs-4 p-3 pb-0">
        {{ info }}
      </div>
    </div>
    <div class="row p-3" v-if="this.proform['invoice_type'] == 'Proform'">
      <div class="col">
        {{ info2 }}
      </div>
    </div>
    <div
      class="form-check checkbox-xl"
      v-for="(item, index) in items"
      :key="index"
    >
      <input
        class="form-check-input"
        type="checkbox"
        :id="item.id"
        :value="item"
        v-model="selectedItems"
        :disabled="!item.doc_nr"
      />
      <label class="form-check-label" :for="item.id"
        >Dokument: <b>{{ item.doc_nr }}</b> Status:
        <b>{{ getStatus(item.invoice_status) }}</b> Datum:
        <b>{{ getDate(item.doc_date) }}</b> Iznos:
        <b>{{ iznos[item.id] }}</b> Uplate:
        <b>{{ uplaceno[item.id] }}</b></label
      >
    </div>
    <button
      type="button"
      :class="`btn btn-primary mt-4 me-2`"
      @click="createNormal"
      :disabled="false"
    >
      <div class="d-inline-flex align-items-center bi bi-journal-plus">
        &nbsp;Kreiraj Račun
      </div>
    </button>
  </div>
</template>

<script>
import InvoiceDataService from "../../services/invoice.service";
import utils from "../../utils/common";
import invoiceUtils from "./invoice-utils.js";
import dateUtils from "../../utils/date-common";
import con from "../../constants/constants";
import NormalForm from "./NormalForm.vue";

export default {
  name: "checkboxlist",
  components: {
    NormalForm,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    // proformNr: {
    //   type: String,
    //   required: true,
    // },
    proform: {
      required: true,
    },
    showForm: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      items: [],
      selectedItems: [],
      info: "",
      info2:
        "Ukoliko ne izaberete ni jedan avans, uplate će biti iskopirane sa predračuna.",
      iznos: {},
      uplaceno: {},
      formData: {},
    };
  },

  methods: {
    createNormal() {
      if (this.showForm) {
        if (!this.formData.exchange_value) {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: "Morate uneti kurs",
          });
          return;
        }
        if (!this.formData.price_exc) {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: "Morate uneti iznos u valuti",
          });
          return;
        }
        let newData = {
          ...this.formData,
          ref_doc_nr: this.proform ? this.proform.doc_nr : null,
          ids: this.selectedItems.map((s) => s.id),
        };
        if (newData.ids.length == 0) {
          newData["payment"] = this.proform.invoice_payments;
        }
        invoiceUtils.createNormalWithReservation(
          this.proform.reservation_id,
          "Normal",
          newData
        );
      } else {
        invoiceUtils.createDoc(
          this.proform.id,
          "Normal",
          this.selectedItems.map((s) => s.id)
        );
      }
    },
    getStatus(status) {
      return con.statuses[status].label;
    },
    getDate(date) {
      return dateUtils.formatDate(date);
    },
    inputChanged(data) {
      console.log({ data, fd: this.formData });
      this.formData = data;
    },
  },
  async mounted() {
    if (this.proform) {
      InvoiceDataService.getByRef(this.proform.doc_nr.replaceAll("/", "|"))
        .then((list) => {
          this.items = list.data.filter(
            (i) => i.invoice_type == "Advance"
          );
          this.info =
            this.items.length > 0
              ? "Izaberite Avansne račune sa kojih treba iskopirati uplatu na završni račun."
              : "Ne postoje vezani Avansni računi. Uplate ce biti iskopirane sa predračuna.";
          this.info2 = this.items.length > 0 ? this.info2 : "";
          this.items.forEach((invoice) => {
            this.iznos[invoice.id] = 0;
            this.uplaceno[invoice.id] = 0;
            this.iznos[invoice.id] =
              this.iznos[invoice.id] + invoice.invoice_items.length > 0
                ? invoice.invoice_items.reduce((accumulator, object) => {
                    return Number.parseFloat(
                      Number.parseFloat(accumulator) +
                        object.price_exc * object.quantity
                    ).toFixed(2);
                  }, 0)
                : 0;
            this.uplaceno[invoice.id] =
              this.uplaceno[invoice.id] + invoice.invoice_payments.length > 0
                ? invoice.invoice_payments.reduce((accumulator, object) => {
                    return Number.parseFloat(
                      Number.parseFloat(accumulator) + object.payment_exc
                    ).toFixed(2);
                  }, 0)
                : 0;
          });
          this.selectedItems = this.items.filter((i) => i.invoice_status == 'Done');
        })
        .catch((err) => {
          utils.showAlert("error", err);
        });
    }
  },
};
</script>

<style scoped>
.checkbox-lg .form-check-input {
  top: 0.8rem;
  scale: 1.4;
  margin-right: 0.7rem;
}

/* .checkbox-lg .form-check-label {
  padding-top: 13px;
} */

.checkbox-xl .form-check-input {
  top: 1.2rem;
  scale: 1.7;
  margin-right: 0.8rem;
}

/* .checkbox-xl .form-check-label {
  padding-top: 19px;
} */
</style>