import store from "../../store";
import ArticlesDataService from "../../services/articles.service";
import InvoiceDataService from "../../services/invoice.service";
import ReservationService from '../../services/reservation.service';
import utils from "../../utils/common";
import router from '../../router';

export default {
    updateSum(tableSum, itemList, taxRates) {
        Object.keys(tableSum).forEach((k) => {
            delete tableSum[k];
        });
        if (itemList.length == 0) {
            return;
        }
        for (const item of itemList) {
            tableSum["medjuzbir"] = {
                label: "Medjuzbir/Subtotal",
                sum: Number.parseFloat(
                    Number.parseFloat(
                        tableSum["medjuzbir"] ? tableSum["medjuzbir"]["sum"] : 0
                    ) +
                    item.price * item.quantity
                ).toFixed(2),
                sum_exc: Number.parseFloat(
                    Number.parseFloat(
                        tableSum["medjuzbir"]
                            ? tableSum["medjuzbir"]["sum_exc"]
                            : 0
                    ) +
                    item.price_exc * item.quantity
                ).toFixed(2),
            };
        }

        //medjuzbir
        tableSum["medjuzbir"] = {
            label: "Medjuzbir/Subtotal",
            sum: itemList.reduce((accumulator, object) => {
                return Number.parseFloat(
                    Number.parseFloat(accumulator) + object.price * object.quantity
                ).toFixed(2);
            }, 0),
            sum_exc: itemList.reduce((accumulator, object) => {
                return Number.parseFloat(
                    Number.parseFloat(accumulator) + object.price_exc * object.quantity
                ).toFixed(2);
            }, 0),
        };
        // VAT calc
        var res = {};

        itemList.map((e) => {
            const tax = taxRates.find((t) => t.id == e.article.tax);

            res[`osn_${tax.name}`] = {
                label: `Osnovica PDV / VAT ${tax.rate}`,
                sum: Number.parseFloat(Number.parseFloat(res[`osn_${tax.name}`] ? res[`osn_${tax.name}`]["sum"] : "0") + Number.parseFloat(e.price * e.quantity)).toFixed(2),
                sum_exc: Number.parseFloat(Number.parseFloat(res[`osn_${tax.name}`] ? res[`osn_${tax.name}`]["sum_exc"] : "0") + Number.parseFloat(e.price_exc * e.quantity)).toFixed(2),
            };
        });

        // Add VAT
        Object.keys(res).forEach(
            (e) =>
            (tableSum[e] = {
                ...res[e],
            })
        );

        res = {};

        itemList.map((e) => {
            const tax = taxRates.find((t) => t.id == e.article.tax);

            res[tax.name] = {
                label: `PDV / VAT ${tax.rate}`,
                sum: Number.parseFloat(Number.parseFloat(res[tax.name] ? res[tax.name]["sum"] : "0") + Number.parseFloat(e.tax * e.quantity)).toFixed(2),
                sum_exc: Number.parseFloat(Number.parseFloat(res[tax.name] ? res[tax.name]["sum_exc"] : "0") + Number.parseFloat(e.tax_exc * e.quantity)).toFixed(2),
            };
        });

        // Add VAT
        Object.keys(res).forEach(
            (e) =>
            (tableSum[e] = {
                ...res[e],
            })
        );

        //Add Sum
        tableSum["ukupno"] = {
            label: "Ukupno za uplatu / Total to be paid",
            sum: Object.keys(tableSum)
                .filter((k) => k != "ukupno" && !k.startsWith('osn_'))
                .reduce((accumulator, key) => {
                    return Number.parseFloat(
                        Number.parseFloat(accumulator) + Number.parseFloat(tableSum[key].sum)
                    ).toFixed(2);
                }, 0),
            sum_exc: Object.keys(tableSum)
                .filter((k) => k != "ukupno" && !k.startsWith('osn_'))
                .reduce((accumulator, key) => {
                    return Number.parseFloat(
                        Number.parseFloat(accumulator) + Number.parseFloat(tableSum[key].sum_exc)
                    ).toFixed(2);
                }, 0),
        };
    },

    async addItem(newItem, itemData, itemList, taxRates, sellType, additional = {}) {
        //Check data
        if (
            !newItem.article_id ||
            !newItem.quantity ||
            !newItem.price ||
            !newItem.price_exc
        ) {
            store.commit("app/showAlert", {
                type: "warning",
                message: "Sva polja moraju biti popunjena!",
            });
            return;
        }
        const resp = await ArticlesDataService.get(newItem.article_id);
        if (additional && resp.data.additional_required) {
            additional.contract_nr = additional.contract_nr ?? '';
            additional.km = additional.km ?? '';
            additional.driver = additional.driver ?? '';
            additional.pickup_date = additional.pickup_date ?? '';
            additional.return_date = additional.return_date ?? '';
            additional.registration_nr = additional.registration_nr ?? '';
            additional.model = additional.model ?? '';
        }
        
        if (additional && resp.data.wheels_nr_required) {
            additional.wheels_nr = additional.wheels_nr ?? '';
        }
        // console.log('Normal', additional, resp.data.wheels_nr_required);
        const tax = taxRates.find((t) => t.id == resp.data.tax);
        const taxKoef = ((Number.parseFloat(tax.rate) / 100) + 1);
        itemList.push({
            ...newItem,
            price: Number.parseFloat(newItem.price).toFixed(2),
            price_exc: Number.parseFloat(newItem.price_exc).toFixed(2),
            tax:
                newItem.tax ? newItem.tax :
                    sellType == 'MP' ? ((Number.parseFloat(newItem.price) * (1 - 1 / taxKoef))).toFixed(2) : ((Number.parseFloat(newItem.price) * (Number.parseFloat(tax.rate) / 100))).toFixed(2),
            tax_exc:
                newItem.tax_exc ? newItem.tax_exc :
                    sellType == 'MP' ? ((Number.parseFloat(newItem.price_exc) * (1 - 1 / taxKoef))).toFixed(2) : ((Number.parseFloat(newItem.price_exc) * (Number.parseFloat(tax.rate) / 100))).toFixed(2),
            article: resp.data,
        });
        itemData.price = "";
        itemData.price_exc = "";
        itemData.article_id = "";
        itemData.quantity = 1;
        itemData.article_id = "";
    },

    removeItem(index, itemList) {
        itemList.splice(index, 1);
    },
    stornoDocument(data, back = false) {
        utils.showDialog(
            data,
            "Da li ste sigurni da zelite da stornirate račun?",
            "Storniranje računa: " + data.doc_nr,
            () => {
                // utils.showAlert("info", `Opcija storno još uvek nije dostupna 🤷`);
                // utils.confirmNo()
                if (!data.saop_num) {
                    InvoiceDataService.update(data.id, { invoice_status: 'Deleted', storno_date: Date() }).then(() => {
                        utils.showAlert("error", `Dokument je storniran`);
                        utils.confirmNo()
                        if (back) router.go(-1);
                    }).catch((err) => {
                        utils.showAlert("error", err.message ?? `Doslo je do greske`);
                        utils.confirmNo()
                    })
                } else {
                    InvoiceDataService.toSaop(data.id, true)
                        .then((res) => {
                            console.log({ res });
                            if (res.data.message) {
                                utils.showAlert("error", res.data.message ?? `Doslo je do greske`);
                                utils.confirmNo()
                            } else {
                                // utils.showAlert("success", `Dokument je storniran`);
                                // utils.confirmNo()
                                InvoiceDataService.update(data.id, { invoice_status: 'Deleted', storno_date: Date() }).then(() => {
                                    utils.showAlert("error", `Dokument je storniran`);
                                    utils.confirmNo()
                                    router.go();
                                    if (back) router.go(-1);
                                }).catch((err) => {
                                    utils.showAlert("error", err.message ?? `Doslo je do greske`);
                                    utils.confirmNo()
                                })
                            }
                        })
                        .catch((e) => {
                            utils.showAlert("error", e ?? `Doslo je do greske`);
                            utils.confirmNo()
                        });
                }
            }
        );
    },
    deleteDocument(data, back = false) {
        utils.showDialog(
            data,
            "Da li ste sigurni da zelite da obrisete račun?",
            "Brisanje računa: " + data.id,
            () => {
                InvoiceDataService
                    .delete(data.id)
                    .then((response) => {
                        store.commit("app/showAlert", {
                            type: "success",
                            message: response.data.message,
                        });
                        store.commit("app/hideModal");
                        if (back) {
                            router.go(-1);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        store.commit("app/showAlert", {
                            type: "danger",
                            message: e,
                        });
                    });
            }
        );
    },
    async createDoc(id, type, ids) {
        InvoiceDataService.createDocument({ id: id, type: type, ids })
            .then((res) => {
                store.commit("app/showAlert", {
                    type: "success",
                    message: "Dokument je kreiran!",
                });
                utils.confirmNo();
                router.push({
                    path: `/invoice/${type}/${res.data.data.id}`,
                });
            })
            .catch((e) => {
                store.commit("app/showAlert", {
                    type: "danger",
                    message: e,
                });
            });
    },
    async createAdvance(id) {
        utils.showDialog(
            { id: id },
            "Da li ste sigurni da zelite da kreirate avansni račun?",
            "Kreiranje avansnog računa: " + id,
            () => {
                this.createDoc(id, 'Advance');
            }
        );
    },
    async createNormal(id) {
        utils.showDialog(
            { id: id },
            "Da li ste sigurni da zelite da kreirate račun?",
            "Kreiranje računa: " + id,
            () => {
                this.createDoc(id, 'Normal');
            }
        );
    },
    deletedDocument(back = false) {
        utils.showAlert("danger", `Nije moguce obrisati stornirani dokument!`);
        utils.confirmNo();
        if (back) router.go(-1);
    },
    updateField(val, cached, paymentData, field, field_exc, exc) {
        if (!exc) {
            store.commit("app/showAlert", {
                type: "warning",
                message: "Morate izabrati kurs!",
            });
            return;
        }
        if (cached[field] != val) {
            paymentData[field] = Number.parseFloat(val).toFixed(2);
            paymentData[field_exc] = Number.parseFloat(
                val / exc
            ).toFixed(2);
            cached[field_exc] = paymentData[field_exc];
            cached[field] = Number.parseFloat(val).toFixed(2);
        }
    },
    updateFieldExc(val, cached, paymentData, field, field_exc, exc) {
        if (!exc) {
            store.commit("app/showAlert", {
                type: "warning",
                message: "Morate izabrati kurs!",
            });
            return;
        }
        if (cached[field_exc] != val) {
            paymentData[field_exc] = Number.parseFloat(val).toFixed(2);
            paymentData[field] = Number.parseFloat(
                val * exc
            ).toFixed(2);
            cached[field] = paymentData[field];
            cached[field_exc] = Number.parseFloat(val).toFixed(2);
        }
    },

    print(type, id, val = 1) {
        let routeData = router.resolve({
            path: `/invoice/print/${type}/${id}/${val}`,
        });
        window.open(routeData.href, "_blank");
    },

    updateTotalSum(tableTotalSum, payments, tableSum) {
        if (payments.length == 0) {
            Object.keys(tableTotalSum).forEach((k) => {
                delete tableTotalSum[k];
            });
            return;
        }
        tableTotalSum['sum'] = {
            label: "Zbir uplata",
            sum: payments.reduce((accumulator, object) => {
                return Number.parseFloat(
                    Number.parseFloat(accumulator) + Number.parseFloat(object.payment)
                ).toFixed(2);
            }, 0),
            sum_exc: payments.reduce((accumulator, object) => {
                return Number.parseFloat(
                    Number.parseFloat(accumulator) + Number.parseFloat(object.payment_exc)
                ).toFixed(2);
            }, 0),
        }

        if (tableSum.ukupno) {
            tableTotalSum['rest'] = {
                label: "Preostalo za uplatu",
                sum: Number.parseFloat(tableSum['ukupno']['sum'] - tableTotalSum['sum']['sum']).toFixed(2),
                sum_exc: Number.parseFloat(tableSum['ukupno']['sum_exc'] - tableTotalSum['sum']['sum_exc']).toFixed(2),
            }
            if (tableTotalSum['rest']['sum'] < 0) {
                tableTotalSum['rest']['label'] = 'Iznos za povraćaj';
                tableTotalSum['rest']['sum'] = Number.parseFloat(tableTotalSum['rest']['sum'] * -1).toFixed(2);
                tableTotalSum['rest']['sum_exc'] = Number.parseFloat(tableTotalSum['rest']['sum_exc'] * -1).toFixed(2);
            }
        }
    },
    createAdvanceWithReservation(reservationId, type, newData) {
        return ReservationService.createDocument({
            id: reservationId,
            type: type,
            exchange: newData.exchange,
            exchange_date: newData.exchange_date,
            exchange_value: newData.exchange_value,
            ref_doc_nr: newData.ref_doc_nr,
            items: store.state.app.articles[type].map((i) => {
                const tax = store.state.app.taxRates.find((t) => t.id == i.tax);
                const koef = 1 + Number.parseFloat(tax.rate) / 100;
                const koefTax = 1 - 1 / (1 + Number.parseFloat(tax.rate) / 100);
                let price = newData.price;
                let price_exc = newData.price_exc;
                return {
                    quantity: 1,
                    price: price / koef,
                    price_exc: price_exc / koef,
                    tax: price * koefTax, //(1 - 1 / taxKoef),
                    tax_exc: price_exc * koefTax, //(1 - 1 / taxKoef),
                    article_id: i.id,
                };
            }),
            payments_ids: newData.payments.map((p) => p.id),
            payments: [...newData.payments].map(p => {
                delete p.id;
                delete p.advance_id;
                return p;
            }),
            
        }).then((res) => {
            store.commit("app/showAlert", {
                type: "success",
                message: "Dokument je kreiran!",
            });
            utils.confirmNo();
            router.push({
                path: `/invoice/${type}/${res.data.data.id}`,
            });
        })
            .catch((e) => {
                store.commit("app/showAlert", {
                    type: "danger",
                    message: e,
                });
            });
    },
    createNormalWithReservation(reservationId, type, newData) {
        console.log({ reservationId, type, newData });
        return ReservationService.createDocument({
            id: reservationId,
            type: type,
            its_no: newData.its_no,
            incoming_invoice: newData.incoming_invoice,
            exchange: newData.exchange,
            exchange_date: newData.exchange_date,
            exchange_value: newData.exchange_value,
            ref_doc_nr: newData.ref_doc_nr,
            ids: newData.ids,
            items: store.state.app.articles[type].map((i) => {
                const tax = store.state.app.taxRates.find((t) => t.id == i.tax);
                const koef = 1 + Number.parseFloat(tax.rate) / 100;
                const koefTax = 1 - 1 / (1 + Number.parseFloat(tax.rate) / 100);
                let price = Number.parseFloat(
                    newData.price_exc * newData.exchange_value
                ).toFixed(2);
                let price_exc = newData.price_exc;
                const ofee =
                    newData.price_exc -
                    newData.osnovica * (newData.osnovica_p / 100) -
                    newData.osnovica * (newData.osnovica_p / 100) * 0.2;
                if (Number.parseFloat(tax.rate) == 0.0) {
                    if (newData.osnovica > 0) {
                        price = (ofee - ofee * 0.05) * newData.exchange_value;
                        price_exc = ofee - ofee * 0.05;
                    } else {
                        price = price - price * 0.05;
                        price_exc = price_exc - price_exc * 0.05;
                    }
                }
                if (Number.parseFloat(tax.rate) == 20.0) {
                    if (newData.osnovica > 0) {
                        price =
                            Number.parseFloat(price) -
                            (ofee - ofee * 0.05) * newData.exchange_value;
                        price_exc = Number.parseFloat(price_exc) - (ofee - ofee * 0.05);
                    } else {
                        price = price * 0.05;
                        price_exc = price_exc * 0.05;
                    }
                }
                return {
                    quantity: 1,
                    price: price / koef,
                    price_exc: price_exc / koef,
                    tax: price * koefTax, //(1 - 1 / taxKoef),
                    tax_exc: price_exc * koefTax, //(1 - 1 / taxKoef),
                    article_id: i.id,
                };
            }),
            // Payments
            payments: newData.ids && newData.ids.length > 0 ? null : [...newData.payment].map(p => {
                delete p.id;
                return p;
            }),
        }).then((res) => {
            store.commit("app/showAlert", {
                type: "success",
                message: "Dokument je kreiran!",
            });
            utils.confirmNo();
            router.push({
                path: `/invoice/${type}/${res.data.data.id}`,
            });
        })
            .catch((e) => {
                store.commit("app/showAlert", {
                    type: "danger",
                    message: e,
                });
            });
    },
}